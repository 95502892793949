<template>
  <div>
    <window-header></window-header>
    <nav>
      <button class="button button-fill" @click="saveEnlaces">
        <fa-icon icon="save" />
        <span>{{$t('rform.save')}}</span>
      </button>
    </nav>
    <section>
      <article style="height: 300px; width:500px; display:flex;flex-direction: column">
        <div class="row">
          <div>Disponibles</div>
          <div>Seleccionados</div>
        </div>
        <div class="row" style="flex:1;">
          <div style="height: 100%;display:flex;flex-direction: column;width:50%;">
            <Container
              behaviour="copy"
              group-name="col"
              class="sortable-block"
              :get-ghost-parent="getGhostParent"
              :get-child-payload="function(){return{descripcion:'_line_'}}"
            >
              <Draggable>
                <div class="draggable-item">Separador</div>
              </Draggable>
            </Container>
            <Container
              style="flex:1;"
              group-name="col"
              class="sortable-block"
              @drop="onDrop('listInactivo', $event)"
              :get-ghost-parent="getGhostParent"
              :get-child-payload="getChildPayload('listInactivo')"
            >
              <Draggable :key="'enlace-'+item.id+'-'+i" v-for="(item,i) in listInactivo">
                <template v-if="!item.delete">
                  <div class="draggable-item" v-if="item.descripcion=='_line_'">
                    <hr />
                  </div>
                  <div class="draggable-item" v-else>{{item.descripcion}}</div>
                </template>
              </Draggable>
            </Container>
          </div>
          <Container
            group-name="col"
            class="sortable-block"
            style="width:50%;"
            @drop="onDrop('listActivo', $event)"
            :get-ghost-parent="getGhostParent"
            :get-child-payload="getChildPayload('listActivo')"
          >
            <Draggable :key="'enlace-'+item.id+'-'+i" v-for="(item,i) in listActivo">
              <div class="draggable-item" v-if="item.descripcion=='_line_'">
                <hr />
              </div>
              <div class="draggable-item" v-else>{{item.descripcion}}</div>
            </Draggable>
          </Container>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
import WindowMixin from "./../components/WindowMixin.vue";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: { Container, Draggable },
  mixins: [WindowMixin],
  data: function() {
    return {
      title: "Enlaces del menú",
      listActivo: [],
      listInactivo: []
    };
  },
  mounted() {
    var self = this;
    self.listActivo = this.app.enlaces
      .filter(a => parseInt(a.activo))
      .sort((a, b) => (parseInt(a.posicion) > parseInt(b.posicion) ? 1 : -1));
    self.listInactivo = this.app.enlaces
      .filter(a => !parseInt(a.activo))
      .sort((a, b) => (parseInt(a.posicion) > parseInt(b.posicion) ? 1 : -1));
  },
  methods: {
    saveEnlaces() {
      var self = this;
      let p = Promise.resolve();
      self.listActivo.forEach(function(item, i) {
        let data = {
          posicion: i,
          activo: 1,
          descripcion: item.descripcion
        };
        if (item.id) {
          p = p.then(
            window.DB.put("enlace", {
              itemId: item.id,
              data
            })
          );
        } else {
          p = p.then(
            window.DB.post("enlace", {
              data
            })
          );
        }
      });
      self.listInactivo.forEach(function(item, i) {
        let data = {
          posicion: i,
          activo: 0,
          descripcion: item.descripcion
        };
        if (item.delete) {
          p = p.then(
            window.DB.delete("enlace", {
              itemId: item.id
            })
          );
        } else if (item.id) {
          p = p.then(
            window.DB.put("enlace", {
              itemId: item.id,
              data
            })
          );
        } else {
          p = p.then(
            window.DB.post("enlace", {
              data
            })
          );
        }
      });
      p.then(function() {
        self.app.toast("Guardado con éxito", "success");
        window.DB.getList("enlace").then(function(res) {
          self.app.enlaces = res.data;
          self.listActivo = self.app.enlaces
            .filter(a => parseInt(a.activo))
            .sort((a, b) =>
              parseInt(a.posicion) > parseInt(b.posicion) ? 1 : -1
            );
          self.listInactivo = self.app.enlaces
            .filter(a => !parseInt(a.activo))
            .sort((a, b) =>
              parseInt(a.posicion) > parseInt(b.posicion) ? 1 : -1
            );
        });
      }).catch(function() {
        self.app.toast("Ocurrió un error al guardarrrr", "error");
      });
    },
    onDrop: function(listName, dropResult) {
      this[listName] = applyDrag(this[listName], dropResult);
      if (
        dropResult.payload.descripcion == "_line_" &&
        listName == "listInactivo"
      ) {
        dropResult.payload.delete = true;
        //this[listName].splice(dropResult.addedIndex, 1);
      }
    },
    getGhostParent() {
      return document.body;
    },
    getChildPayload(listName) {
      return index => {
        return this[listName][index];
      };
    }
  }
};
</script>
<style>
.sortable-block {
  overflow: auto;
}
.main-sortable-block {
  height: 100%;
  display: flex;
}
.draggable-item {
  padding: 5px;
  border: 1px solid #ccc;
  margin: 3px;
  background: white;
}
.row {
  padding: 4px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow: auto;
}
</style>